<template>
    <div>
            <section class="section-coming-soon section-shaped my-0">
                <div class="shape shape-style-1 shape-warning bg-gradient-warning">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div class="container shape-container d-flex align-items-center text-center">
                    <div class="col px-0">
                        <div class="row justify-content-center align-items-center">
                            <div class="col-lg-8">
                              <vue-core-video-player :src="'img/how-to-use-a-moxa-stick.mp4' | imgsrc" autoplay title="How to use a moxa stick"></vue-core-video-player>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </div>
</template>

<script>

export default {
  name: 'how-to-use-a-moxa-stick',
  metaInfo: {
    title: 'How To Use A Moxa Stick'
    // override the parent template and just use the above title only
    // titleTemplate: null
  }
}
</script>
