<template>
    <div>
      <section class="section section-blog section-shaped section-lg my-0">
        <div class="shape shape-style-1 bg-gradient-white">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
        <div class="container pt-lg-md">
            <div class="row justify-content-center">
              <div class="col-lg-12 text-center align-items-center">
            <div class="mb-5 mb-lg-0">
              <h4 class="display-2 underline text-uppercase font-weight-300 blog-post-top-heading">
                Bone Broth Recipe
              </h4>
            </div>
          </div>
              <div class="col-lg-6 text-center">
            <div class="mb-5 mb-lg-0">
              <div class="m-5 text-left">
               <h2 class="display-3 text-black text-uppercase">Ingredients:</h2>
               <ul class="text-uppercase">
                 <li>5 Pounds of bones with marrow</li>
                 <li>2 cow's feet split into place</li>
                 <li>1/2 Cup of chopped carrots</li>
                 <li>1/2 cup of chopped celery</li>
                 <li>1/2 cup of beets</li>
                 <li>1/2 cup of chopped winter squash</li>
                 <li>1/2 cup of chopped mushrooms</li>
                 <li>1 chopped large onion</li>
                 <li>1 minced clove of garlic</li>
                 <li>2 tsp of apple cider vinegar</li>
                 <li>1 tsp each of rosemary, thyme, and parsley</li>
                 <li>5 litres of cold water</li>
               </ul>
               <h2 class="display-3 text-black text-uppercase">Process:</h2>
               <p class="text-uppercase">
                 Preheat the oven to 425 degrees F.
                 Bring a LA POT of water to boil. rinse bones and cow's feet and place in boiling water for 5 minutes.
                 Remove.
                 Put bones and cow's feet in the oven to roast for 1 hour.
                 Put boes and cow's feet in cold water over low heat. Skim scum as it emerges from the surface and discards. once the water is clear, add vinegar, rosemary, parsley, and thyme.
               </p>
              </div>
            </div>
          </div>
              <div class="col-lg-6 text-center">
            <div class="mb-5 mb-lg-0">

            <img
              v-lazy="$options.filters.imgsrc('img/bone-broth-recipe.jpg')"
                class="img-center img-fluid w-75"
                alt="PMS Hormonal Imbalance"
              />

            </div>
          </div>
            </div>

        </div>
    </section>

    </div>
</template>
<style scoped>
.italic {
  font-style: italic;
}
.blog-post-top-heading{
  letter-spacing: .1em;
    line-height: 1.5em;
}
</style>
<script>

export default {
  name: 'bone-broth-recipe',
  metaInfo: {
    title: 'Bone Broth Recipe'
    // override the parent template and just use the above title only
    // titleTemplate: null
  }
}
</script>
