<template>
    <div class="profile-page">
        <section class="section-profile-cover section-shaped my-0 section-black-overlay">
            <div class="shape shape-style-1 shape-primary bg-gradient-warning shape-skew alpha-4">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </section>
        <section class="section section-skew">
            <div class="container">
                <Practitioner></Practitioner>
            </div>
        </section>
    </div>
</template>
<script>
export default {
  name: 'about',
  components: {
    Practitioner: () => import('./components/Practitioner.vue')
  },
  metaInfo: {
    title: 'About'
  }
}
</script>
