<template>
    <div>
      <section class="section section-blog section-shaped section-lg my-0">
        <div class="shape shape-style-1 bg-gradient-white">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
        <div class="container pt-lg-md">
            <div class="row justify-content-center">
              <div class="col-lg-12 text-center align-items-center">
            <div class="mb-5 mb-lg-0">
              <h4 class="display-2 underline text-uppercase font-weight-300 blog-post-top-heading">
                Veggie Broth Recipe
              </h4>
            </div>
          </div>
              <div class="col-lg-6 text-center">
            <div class="mb-5 mb-lg-0">
              <div class="m-5 text-left">
               <h2 class="display-3 text-black text-uppercase">Ingredients:</h2>
               <ul class="text-uppercase">
                 <li>1/2 cup of gou qi zi (Goji berries)</li>
                 <li>1/2 cup of black seasame seeds</li>
                 <li>1/2 cup of chopped carrots</li>
                 <li>1/2 cup of chopped celery</li>
                 <li>1/2 cup of chopped beets</li>
                 <li>1/2 cup of chopped winter squash</li>
                 <li>1/2 cup of chopped mushrooms</li>
                 <li>1 chopped large onion</li>
                 <li>1 minced clove of garlic</li>
                 <li>2 tbsp of apple cider vinegar</li>
                 <li>1 tsp each of resemary, thyme, and parsley</li>
                 <li>5 litres of cold water</li>

               </ul>
               <h2 class="display-3 text-black text-uppercase">Process:</h2>
               <p class="text-uppercase">
                 Place all ingredients and slowly bring to simmer for 60 min. then straing through a fine colander and discard the solids.
                 keeping the liquid as the broth.
               </p>
               <br>
               <p class="text-uppercase">
                 once cooked, put the broth into a mason jar, put a couple in the fridge (they'll keep for 3-4 days). The rest can go in the freezer.
               </p>
              </div>
            </div>
          </div>
              <div class="col-lg-6 text-center">
            <div class="mb-5 mb-lg-0">

            <img
              v-lazy="$options.filters.imgsrc('img/veggie-broth-recipe.jpg')"
                class="img-center img-fluid w-75"
                alt="PMS Hormonal Imbalance"
              />

            </div>
          </div>
            </div>

        </div>
    </section>

    </div>
</template>
<style scoped>
.italic {
  font-style: italic;
}
.blog-post-top-heading{
  letter-spacing: .1em;
    line-height: 1.5em;
}
</style>
<script>

export default {
  name: 'veggie-broth-recipe',
  metaInfo: {
    title: 'Veggie Broth Recipe'
    // override the parent template and just use the above title only
    // titleTemplate: null
  }
}
</script>
