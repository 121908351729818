<template>
    <div>
      <section class="section section-blog section-shaped section-lg my-0">
        <div class="shape shape-style-1 bg-gradient-white">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
        <div class="container pt-lg-md" ref="postContainer">
            <div class="row justify-content-center align-items-center">
              <div class="col-lg-12 text-center">
            <div class="mb-5 mb-lg-0">
              <h4 class="display-2 text-uppercase font-weight-300 blog-post-top-heading">
                Gentle detoxification - Organic Coffee Enema
              </h4>
              <div class="m-5 text-left">
               <h2 class="display-3 text-black italic">Detoxification</h2>
                              <p>
                                Any gentle detoxification protocols will assist the liver in detoxification of estrogen and liver function in general. But please, go gently. If your liver is functioning well you should not feel unwell during a detox. Feeling “sick” while detoxing is an indicator that your liver is unable to clear the toxins being released. This is far from ideal, so I suggest that you support and improve your liver function before attempting any kind of detoxification protocol.
                              </p>
                              <h2 class="display-3 text-black italic">Try a coffee enema</h2>
                              <p>This is a gentle detoxification method that can be carried by yourself out at home. These instructions are based on the handout given at Balihealing Centre, the location of my practice rooms in Indonesia:</p>
                              <h4 class="display-4 text-black">THE COFFEE ENEMA - A LIVER DETOXIFIER</h4>
                              <p>Coffee enemas rapidly support the liver in removing toxins: They often provide quick relief when you are fatigued, sleepy, have headaches, or is just feeling off.

A coffee enema, when done correctly, causes the liver to produce six hundred times more toxins, opens the bile ducts, and causes the bile to flow. In this process, a toxic liver can dump many toxins into the bile and get rid of them through the stool in a short amount of time.

The coffee enema provides a sense of wellbeing. Coffee enemas are also useful in relieving pain. Patients with cancer, for example, may achieve relief from pain even when drugs have failed.

NOTE: Drinking a cup of coffee has an entirely different effect from that of using it as an enema.

Drinking coffee can cause the following problems:</p>
<ul>
  <li>Increases reflex response</li>
  <li>Lowers blood pressure;</li>
  <li>Increases heart rate;</li>
  <li>Can cause insomnia and heart palpitation; </li>
  <li>It overstimulates the adrenals  </li>
  <li>Irritates the stomach  </li>
  <li>Leaves a toxic residue in the body</li>
  <li>Promotes dehydration</li>
  <li>Creates an acidic environment</li>
  <li>In TCM it has a "heating and drying effect not only on the liver but the entire body</li>
</ul>

<h4 class="display-4 text-black">Preparing the Coffee Enema, the right way!</h4>

<ul>
  <li>Add three heaped tablespoons of organic ground coffee to 1 L of water. &nbsp;( NOTE: The Chemicals found in regular coffee can damage the liver, USE ONLY organic coffee! Regular coffee is often high in herbicides and pesticides. )</li>
  <li>Let it boil lightly for three minutes, and then simmer for a total of twenty minutes. Leave the lid on the saucepan.&nbsp;</li>
  <li>Strain, the coffee mixture is now ready to be used.</li>
  <li>(A SHORT CUT - Use one heaped Tablespoon of ground organic coffee per person mixed with boiling water).</li>
  <li>It is beneficial to have opened your bowels before the enema, but don&apos;t stress if you can&apos;t.</li>
  <li>Position yourself, find a warm, comfortable place to do the enema in private &ndash; close to a toilet, where you will be able to relax uninterrupted. The enema bag needs to be hung no more than three feet above your anus. A door handle often works, or you can attach the bag to a coat hanger and hang somewhere.</li>
  <li>Close the tap and fill the enema bag with the coffee solution and top up with warm and cold filtered water to 2L, until the temperature is comfortable for inserting into your body.</li>
  <li>Lubricate the pipe (the small tapered one) and your anus with oil for easy insertion (coconut oil works fine).</li>
  <li>Run a little water through first to clear any air bubbles. Insert the tube gently into the anus and open the clip (leave the tap parallel to the tube and don&apos;t move it again).&nbsp;</li>
  <li>Relax. Play some music or have a book to hand. You may like to either raise your hips on a pillow or lie on your left side. Some people find being on all fours the most effective position.&nbsp;</li>
  <li>Take in as much water as you are comfortable with and hold it for as long as you can. After a minute, try taking a little more and hold once again. Don&apos;t expect yourself to take in the whole bag of liquid straight off. Little by little is the way! If the water is not flowing, gently rotate or move the tube in and out until there is flow. According to Dr Gearson, holding the coffee enema twelve minutes is ideal.&nbsp;</li>
  <li>Breathe deeply, so that you can absorb the most amount of fluid into as much of the colon as possible. It helps to exhale the air out of the lungs so you can suck the colon in and push out while in this position. (yogi&apos;s know this movement well).</li>
  <li>Massage your abdomen, especially the descending colon, positioned on the left side of the body (see diagram below). ALWAYS massage following the shape/direction of your colon.&nbsp;</li>
  <li>When you are ready to release, position yourself on the toilet. Keep your knees raised and again massage your tummy, aiming to feel anywhere that is tight or blocked. You can put your feet up on a chair in front of the toilet if it&apos;s easier.</li>
  <li>Repeat until you feel you&apos;ve had sufficient release. As you take in more water, work your way around to the transverse &amp; ascending colon. Ideally, you want to flush as much of the colon as possible.</li>
</ul>
<h5 class="text-black">AFTERCARE</h5>
<ul>
  <li>Re-balance your body by taking good quality probiotics, drinking fresh vegetable juices, eating juicy green salads & drinking plenty of water. Rehydrate with a quality mineral supplement.</li>
</ul>
<img v-lazy="$options.filters.imgsrc('img/colon-anatomy.png')" alt="PMS Hormonal Imbalance" class="img-fluid">

<h4 class="display-4 text-black">OTHER TYPES OF ENEMAS </h4>
<ul>
  <li>Garlic: crush two cloves of garlic & soak in l water for an hour, or blend the garlic cloves. Then strain & add the garlic water to the enema bag. Great for fungi, yeast and parasites. </li>
  <li>Barley grass/Wheatgrass: add fresh juice, or mixed up powder to your enema bag for a chlorophyll infusion.</li>
</ul>
              </div>

            </div>
          </div>
            </div>

        </div>
    </section>

    </div>
</template>
<style scoped>
.italic {
  font-style: italic;
}
.blog-post-top-heading{
  letter-spacing: .1em;
    line-height: 1.5em;
}
</style>
<script>

export default {
  name: 'gentle-detoxification-organic-coffee-enema',
  metaInfo: {
    title: 'Gentle Detoxification - Organic Coffee Enema'
    // override the parent template and just use the above title only
    // titleTemplate: null
  }
}
</script>
